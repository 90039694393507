import * as React from "react"

import LayoutWhite from "../components/layoutWhite"

const PreguntasFrecuentesPage = () => (
  <LayoutWhite
	seccion="preguntasFrecuentes"	
	title="Preguntas Frecuentes"	
	linkTo="experienciaContainer"
	>		
			<div id="whiteContainer" className="row">
				<div className="col-12"
						data-aos='fade-up'
						data-aos-duration="600" data-aos-once="true">
					<h1 className="">Preguntas Frecuentes</h1>
					
					<p className="pregunta">¿Qué es courier internacional?</p>
					<p className="respuesta">El servicio Courier consiste en el envío de documentos o paquetes de un tamaño y/o peso limitado a un determinado
costo. Opera bajo el estándar premium en servicios de correo postal o paquetería, el cual es usado por las empresas
que consideran de suma importancia el transporte de sus envíos justificando el costo a pagar por el servicio.
</p>
					<p className="pregunta">¿Se puede consolidar diferentes proveedores en un mismo puerto de origen?</p>
					<p className="respuesta">Sí, y es una ventaja, ya que de esta forma el importador hace un solo despacho, evita la manipulación de la mercadería y
que su negocio sea más rentable.
</p>
					<p className="pregunta">¿Qué volumen máximo puede tener una carga suelta?</p>
					<p className="respuesta">No hay tope, aunque el cliente debe saber que existe un volumen de corte, producto de la división del valor de un full
container versus el valor de 1 cbm, obviamente desde un mismo origen. Dicho volumen de corte marca si es más
rentable cargar en condición de carga suelta o full container.
</p>
					<p className="pregunta">Sobre los embarques aéreos, ¿qué significa peso aforado?</p>
					<p className="respuesta">El “aforo” de la carga es el volumen total multiplicado por 167. La proporción es la siguiente:1 cbm = 167 kgs. En
consecuencia si el resultado da un valor mayor al peso bruto total de la carga, utilizamos ese valor…caso contrario,
usamos el peso bruto total.
</p>
					<p className="pregunta">¿Qué es un manifiesto?</p>
					<p className="respuesta">Es un documento que detalla todo el cargamento de un transporte (marca, número, especie de mercadería, kilos
cargadores, consignatario). Deberá ser presentada por el transportista a las autoridades aduaneras o consulares que lo
requieran.
</p>
					<p className="pregunta">¿Qué es el Transporte Multimodal Internacional?</p>
					<p className="respuesta">Es el porte de mercadería por dos o más modos diferentes de transportes (camión, vagón, buque, aéreo). En virtud del
contrato de transporte, el operador multimodal toma las mercancías bajo su responsabilidad y custodia para entregarla
en otro lugar situado en un país diferente.<br/>
Este sistema se distingue del transporte segmentado tradicional porque se trata de un sistema basado en un “contrato
único” y el segundo lugar porque el operador del transporte multimodal asume la responsabilidad del cumplimiento del
contrato como “principal” y no como agente del usuario o expedidor. Este principio se basa en el servicio de transporte
de la mercadería de “puerta a puerta” reduciendo notablemente la documentación y el cumplimiento de otras
formalidades como en el transporte tradicional.
</p>
					<p className="pregunta">¿Qué es una condición EXW?</p>
					<p className="respuesta">Ex-works, ex-factory, ex-warehouse, ex-mill. El vendedor ha cumplido su obligación de entrega al poner la mercadería
en su fábrica, taller a disposición del comprador. No es responsable ni de cargar la mercadería en el vehículo
proporcionado por el comprador ni de despacharla de aduana para la exportación, salvo acuerdo en otro sentido. El
comprador soporta todos los gastos y riesgos de retirar la mercadería desde el domicilio del vendedor hasta su destino
final.
</p>
					<p className="pregunta">¿Qué es una condición FCA?</p>
					<p className="respuesta">Free carrier (Franco Transportista – libre transportista) El vendedor cumple con su obligación al poner la mercadería en
el lugar fijado, a cargo del transportista, luego de su despacho de aduana para la exportación. Si el comprador no ha
fijado ningún punto específico, el vendedor puede elegir dentro de la zona estipulada el punto donde el transportista se
hará cargo de la mercadería. Este término puede usarse con cualquier modo de transporte, incluído el multimodal.
</p>

				</div>			
			</div>
  </LayoutWhite>
)

export default PreguntasFrecuentesPage